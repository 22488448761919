import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-24 bg-cream-bg sticky top-0 z-10">
      {/* HEADER */}
      <div className="flex items-center justify-between w-full h-24 shadow-xl sticky top-0">
        {/* LOGO */}
        <button
          className="flex items-center flex-shrink-0 ml-8"
          onClick={() => navigate("../")}
        >
          <img
            src={require(`../assets/blak obor.png`)}
            className="object-contain h-16 w-16"
          />
          <span className="text-black text-3xl font-semibold font-plusjakartasans -ml-3">
            Smakonecup
          </span>
        </button>
        {/* LOGIN BUTTON */}
        {/* <div className="justify-self-end items-center mr-10">
          <button
            className="flex items-center justify-center h-14 w-32  bg-[#E09D42] outline outline-2"
            onClick={() => navigate("../login")}
          >
            <span className="text-black text-2xl font-semibold font-plusjakartasans">
              Login
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
