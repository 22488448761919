import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaSearch, FaTwitter } from "react-icons/fa";

const Tags = ({ handleTag, selectedLevel, searchInput, updateSearchInput }) => {
  const level = ["Pilih di sini", "SMP", "SMA", "Umum"];

  //useEffect
  return (
    <div className="my-10">
      <div className="pr-5 text-2xl font-bold font-plusjakartasans ml-5 text-black">
        Jenjang
      </div>
      <div className="flex w-full justify-between">
        <div className="ml-4 mt-3 justify-self-start">
          <select
            onChange={(e) => handleTag(e.target.value)}
            className="py-3 px-28 text-2xl text-black font-plusjakartasans outline outline-2 bg-cream-bg"
          >
            {level.map((lev, index) => (
              <option key={index} value={lev}>
                {lev}
              </option>
            ))}
          </select>
        </div>
        <div className="relative mr-4 mt-3 justify-self-end">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            {/* <i class="fas fa-search text-gray-400"></i> */}
            <FaSearch size={32} />
          </span>
          <input
            className="py-3 pl-12 text-2xl bg-cream-bg font-plusjakartasans outline outline-2"
            value={searchInput}
            onChange={updateSearchInput}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Tags;
