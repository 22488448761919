import Badminton from '../assets/poster/Badminton.jpg';
import Band from '../assets/poster/BAND.webp';
import Basket from '../assets/poster/basket.jpg';
import Business from '../assets/poster/business-plan.jpg';
import English from '../assets/poster/eds.jpg';
import Fotografi from '../assets/poster/fotografi.jpg';
import Futsal from '../assets/poster/futsal.jpg';
import IPA from '../assets/poster/IPA.webp';
import IPS from '../assets/poster/ips.jpg';
import Matematika from '../assets/poster/Matematika.jpg';
import Modern from '../assets/poster/MD.jpg';
import Model from '../assets/poster/MUN.jpg';
import Mural from '../assets/poster/Mural.jpg';
import Speech from '../assets/poster/speech.jpg';
import Voli from '../assets/poster/Voli.jpg';
import Short from '../assets/poster/shortmovie.webp';
import Esports from '../assets/poster/esports.jpg';


const posters = {Badminton, Band, Basket, Business, English, Fotografi, Futsal, IPA, IPS, Matematika, Modern, Model, Mural, Speech, Voli, Short, Esports};

export default posters;