import React from "react";
import { FaTiktok } from "react-icons/fa";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";

const Homepage = () => {
  return (
    <div className="bg-cream-bg py-8 ">
      <div className="flex justify-center items-center  bottom-0 z-10  ">
        {/* center div */}
        <div className="w-3/5">
          <div className="inline-flex items-end ml-[-0.6em] lg:mb-4">
            <div className="flex h-5 lg:h-24 lg:pt-3 grayscale lg:mt-2">
              <img
                src={require(`../assets/blak obor.png`)}
                alt="logo"
                className="grayscale"
              />
            </div>
            <p className="font-plusjakartasans -ml-6 md:font-medium lg:font-bold md:text-xl lg:text-5xl text-[#1F211E]">
              Smakonecup
            </p>
          </div>
          <div className="font-plusjakartasans text-[#1F211E] font-bold text-left text-sm sm:text-base lg:text-xl ml-[0.8em] md:ml-[0.5em] lg:ml-2 xl:mt-2">
            <p className="mb-5">
              Smakonecup Invictus and its logo marks are the property of
              Smakonecup, a part of SMAK 1 PENABUR Jakarta
            </p>
            <p className="mb-10">
              Jl. Tanjung Duren Raya No.4, RT.12/RW.2, Tj. Duren Utara, Kec.
              Grogol petamburan, Kota Jakarta Barat, Daerah Khusus Ibukota
              Jakarta 11470 Copyright© 2022 Smakonecup Atraxia All Rights
              Reserved
            </p>
          </div>
        </div>

        {/* right div */}
        <div className="flex flex-col gap-6 ml-10">
          <div>
            <h1 className="font-plusjakartasans text-[#1F211E] text-right text-xl mt-1 lg:mt-4 sm:text-2xl md:font-medium lg:font-bold lg:text-2xl">
              Contact Us
            </h1>
          </div>
          <div className="text-[#1F211E] flex lg:hidden flex-row-reverse gap-x-2 pb-8 lg:pb-12 xl:pb-8 xl:mt-2">
            <a
              href="https://wa.me/6281299199656?text=kontak%20nomor%20ini%20jika%20ini%20ada%20yang%20mau%20ditanyakan"
              target="blank"
            >
              <BsWhatsapp color="F1F5F9" size={25} />
            </a>
            <a href="https://www.instagram.com/smakonecup/" target="blank">
              <BsInstagram color="F1F5F9" size={25} />
            </a>
            <a href="https://www.tiktok.com/@smakonecup" target="blank">
              <FaTiktok color="F1F5F9" size={25} />
            </a>
          </div>
          <div className="text-[#1F211E] hidden lg:flex flex-row-reverse gap-x-2 pb-8 lg:pb-12 xl:pb-8 xl:mt-2">
            <a
              href="https://wa.me/6281299199656?text=kontak%20nomor%20ini%20jika%20ini%20ada%20yang%20mau%20ditanyakan"
              target="blank"
            >
              <BsWhatsapp color="1F211E" size={30} />
            </a>
            <a href="https://www.instagram.com/smakonecup/" target="blank">
              <BsInstagram color="1F211E" size={30} />
            </a>
            <a href="https://www.tiktok.com/@smakonecup" target="blank">
              <FaTiktok color="1F211E" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Homepage;
